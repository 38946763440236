<template>
  <div class="connection-container">
    <div class="d-flex justify-content-between align-items-center ">
      <div>
        <span class="mx-25 small">Connection</span>
        <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter/>

      </div>
      <b-button class="p-0 pb-25" variant="none" @click="$refs['create-connection-modal'].show()">
        <span class="small add-connection-btn text-dark">
          <b-icon icon="plus" variant="favorite" scale="1.3"/> Add Connection
        </span>
      </b-button>
    </div>

    <v-select 
      v-if="connectionsOrdered" 
      :getOptionLabel="el=>el.name"  
      :reduce="el=> el.identifier"
      :clearable="false"  
      :options="connectionsOrdered" 
      class="z-index-1"
      v-model="register.register_1.value"  
      @input="putNameOnReg2"
    >
      <template #option="data">
        <!-- {{register.register_1.value}} -->
        <div class="d-flex justify-content-between align-items-center" :class="String(data.enum_connection_type_id)!=String(connectionTypeID) ? 'low-opacity' : ''">
          <div>
            <span class="font-weight-bolder"> {{data.name}}</span>
          </div>

          <div v-if="register.register_1.value != data.identifier"> 
            <b-badge variant="light-info" class="text-black mx-50">{{$t(getConnectionType(data.enum_connection_type_id).label)}}</b-badge>
          </div>  

        </div>
      </template>

      <template #selected-option="data">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <span class="font-weight-bolder"> {{data.name}}</span>
          </div>
        </div>
      </template>

    </v-select>

    <create-connection-modal ref="create-connection-modal"/>
  </div>
</template>

<script>

import {
   BFormInput, 
   BFormGroup, 
   BForm, 
   BBadge, 
   BModal,
   BFormCheckbox,
   BSkeleton,
   BCol,
   BButton
} from 'bootstrap-vue'

import HelperTooltip from "@/layouts/components/HelperTooltip.vue";
import VSelect from "vue-select";
import { mapGetters } from "vuex";
import ConnectionTypes from '@/custom/class/Enum/ConnectionTypes';
import CreateConnectionModal from '@/views/pages/middleware/MiddlewareManagement/Components/Modals/CreateConnectionModal.vue';

  export default {
    components: {
      BFormInput, 
      BFormGroup, 
      BForm, 
      BBadge, 
      BModal, 
      BFormCheckbox,
      HelperTooltip,
      BSkeleton,
      VSelect,
      BCol,
      BButton,
      CreateConnectionModal
    },
    data() {
      return {
      }
    },
    props: {
      value: {
        type: Object,
        required: true 
      },
      connectionTypeID:{
        type: Number,
        required: true,
      }
    },
    computed: {
		  ...mapGetters('connection' , ['getConnections']),
      register: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      organizationID(){
        return localStorage.getItem('selectedOrganization')
      },
      connectionsOrdered(){
        let items = this.getConnections

        if (!Array.isArray(items)){
          return undefined
        }
        let typeIDs = []
        
        new ConnectionTypes().items.forEach((el)=>{
          typeIDs.push(el.id)
        })

        let idx = typeIDs.findIndex(el => el == this.connectionTypeID)
        
        if (idx>-1){
          typeIDs.splice(idx,1)
        }

        typeIDs.unshift(this.connectionTypeID)

        let r = []
      
        typeIDs.forEach((type)=>{
          r.push(...items.filter(el=>String(el.enum_connection_type_id) == String(type)))
        })

        return r
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        this.$store.dispatch('connection/getOrgConnections', {organization: this.organizationID})
      },
      getConnectionType(id = undefined){
        let types = new ConnectionTypes().items
        if (id){
          return types.find(el => el.id == id )
        } else {
          return types
        }
      },
      putNameOnReg2(identifier){
        let connections = structuredClone(this.getConnections)

        if (!connections || !identifier){
          this.register.register_2.vale = ''
          return
        }

        let idx = connections.findIndex(el => el.identifier == identifier)
        if (idx > -1){
          let name = connections[idx].name
          this.register.register_2.value = name
          this.register.register_2.source = 7
        }
        
      }
    },
  }
</script>

<style lang="scss" scoped>
.connection-container{
  padding-bottom: 18px;
  //this is so the component occupies the same height as a CustomInput
}
.z-index-1{
  z-index: 1;
}
.add-connection-btn{
  border-bottom: 1px solid var(--favorite);
  font-weight: bold;
  opacity: 0.65;
  font-size: 10px;
}
.low-opacity{
  opacity: 0.25;
}
</style>