<template>
  <div class="d-flex">
    <b-form-group
      class="input-body"
      :label="label"
      :label-for="getID('custom-input-scrollbar')"
    >
      <template #label>
        <slot name="label">
          {{ label }}
        </slot>
      </template>
      <template #description>
        <slot name="changer" v-if="advanced">
          <span class="text-secondary" v-for="type in changerTypes" :key="getID(type)">
            <span class="text-capitalize">
              {{ type }}: 
            </span>
            {{ $t(getValue(type)) }}
            <small v-if="!noChanger" class="text-success cursor-pointer" @click="openChanger(type)">
              - CHANGE 
              <b-icon
                class="mt-25"
                icon="arrow-left-right"
                scale="1"
              />
            </small>
          </span>
        </slot>
      </template>
        <vue-perfect-scrollbar :id="getID('custom-input-scrollbar')" class="border rounded custom-container">
          <drop
            @drop.self="addVariable"
          >
            <div :class="customComputedClasses" class="custom-input shadow py-50 px-1 cursor-text" @click.self="focusLast">
              <span v-for="(item, key) in inputModels" :key="key">
                <b-badge
                  v-if="item.index != '' && item.type != 'constant'"
                  :variant="item.variant"
                >
                  {{ item.index }}
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="keyboardActions({ key: 'Click' }, key)"
                  />
                </b-badge>
                <span
                  v-else
                >
                  <drop
                    @drop="(data) => addVariablePositional(data, key)"
                  >
                    <b-form-textarea
                      placeholder=" +"
                      :ref="getID(key + version)"
                      class="variable-custom-input border rounded pretend-form-input"
                      v-model="inputModels[key].index"
                      :style="`
                        width: ${inputModels[key].index.length ? inputModels[key].index.length * 8.45 + 'px !important' : '25px !important'};
                      `"
                      
                      @keydown="(evt) => keyboardActions(evt, key)"
                      @blur.self="setElements(key)"
                    />
                  </drop>
                </span>
              </span>
            </div>
        </drop>
      </vue-perfect-scrollbar>

      <b-modal
        :id="getID('custom-input-changer')"
        centered
        title="Source Changer"
        size="sm"
        no-close-on-backdrop
        @cancel="submitChanger"
        @ok="submitChanger"
      >
        <v-select
          id="source-select"
          :options="selectChangerOptions"
          v-model="changerSelection"
          :clearable="false"
        >
          <template #option="{ label }">
            <span>{{ label }}</span>
          </template>
            <template #selected-option="{ label }">
            <span>{{ label }}</span>
          </template>
          <template slot="no-options"> {{$t("terminal_tests.terms.no_options")}} </template>
        </v-select>
      </b-modal>
    </b-form-group>
    
    <div class="copy-btn">
      <b-button variant="outline-dark" class="copy-btn-in rounded mt-50" title="Copy to Clipboard" @click="copyCertificateToClipboard()">
        <feather-icon icon="CopyIcon" size="14"/>
      </b-button>
    </div>
</div>
</template>

<script>
import { BFormInput, BFormGroup, BForm, BBadge, BModal, BFormTextarea, BButton, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import Drop from './Drop.vue'
import { v4 as uuidv4 } from 'uuid'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VSelect from 'vue-select'
import { makeToast } from '@/layouts/components/Popups'
import Variable from '../class/Variable'
import Source from '@/custom/class/Agent/Source'
import Sources from '@/custom/class/Enum/Sources.js'


  export default {
    components: {
      BFormInput, 
      BFormGroup, 
      BForm,
      BBadge,
      Drop,
      VuePerfectScrollbar,
      VSelect,
      BModal,
      BFormTextarea,
      BButton,
      BInputGroup, 
      BInputGroupAppend
    },
    props: {
      value: {
        type: [ Object, String ],
        default: () => {}
      },
      possibleValues: {
        type: Object,
        required: true
      },
      horizontal: {
        type: Boolean,
        default: false,
      },
      writeDisabled: {
        type: Boolean,
        default: false
      },
      dropDisabled: {
        type: Boolean,
        default: false
      },
      changerOptions: {
        type: Object,
        default: () => { return {} }
      },
      label: {
        type: String,
        default: ''
      },
      advanced: {
        type: Boolean,
        default: true
      },
      noChanger: {
        type: Boolean,
        default: false
      },
      changerTypes: {
        type: Array,
        default: () => [ 'source' ]
      }
    },
    data() {
      return {
        text: '',
        elements: [],
        items: {},
        inputModels: [],
        inputStateMap: [],
        uuidMap: {},

        version: 0,

        changerSelection: null,
        selectChangerOptions: undefined,

      }
    },
    computed: {
      register: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      customComputedClasses() {
        return `${this.isHorizontal} ${this.isWriteDisabled}`;
      },
      isHorizontal() {
        return this.horizontal ? 'custom-input-horizontal': 'custom-input';
      },
      isWriteDisabled() {
        return this.writeDisabled ? 'custom-input-disabled' : '';
      },
      isDropDisabled() {
        return this.dropDisabled;
      },
      sourceOptions() {
        return new Sources().items.map(el => { return { ...el, label: this.$t(el.label) }})
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        if (this.register == undefined) {
          this.defineRegisters()
        }
        this.setElements()
      },
      defineRegisters() {
        if (this.advanced) {
          this.register = {}
          return
        } 
        this.register = ''
      },
      addVariable(item) {
        if(this.inputModels.at(-1).type != 'variable') {
          this.inputModels.push(item)
        } else {
          this.inputModels.push(new Variable({}), item)
        }
        this.inputModels.push(new Variable({}))
        
        this.tagsToString()
      },
      dropDisabledWarning() {

      },
      addVariablePositional(item, key) { 
        let string = null

        if (key == 0) {
          
          string = new Variable({ type: 'constant', value: '', index: '', variant: 'light-secondary'})
          
          this.inputModels[key] = new Variable({ type: 'constant', value: '', index:  this.inputModels[key].index, variant: 'light-danger'})
          this.inputModels.unshift(string, item)
        } else if (key == (this.inputModels.length - 1)) {
          
          string = new Variable({ type: 'constant', value: '', index: this.inputModels[key].index, variant: 'light-secondary'})
          
          this.inputModels[key] = string
          this.inputModels.push(item, new Variable({ type: 'constant', value: '', index: '', variant: 'light-danger'}) )
        } else {

          string = new Variable({ type: 'constant', value: '', index: '', variant: 'light-secondary'})

          let arrFragment = this.inputModels.splice(key+1)
          arrFragment.unshift(item, string)
          this.inputModels = this.inputModels.concat(arrFragment)
        }

        this.tagsToString()
      },
      keyboardActions(evt, key) {
        // console.log(evt, key)
        if(evt.key == 'Backspace') {
          if (!key) return 
          switch(true) {
            case this.inputModels[key].index == "": {
              this.inputModels.splice(key-1, 2)
              this.setInputFocus(key-2)
              this.version++;
              break;
            }
          }
          this.tagsToString()
        }
        else if(evt.key == 'Delete') {
          if (this.inputModels.length - 1 == key) return
          switch(true) {
            case this.inputModels[key].index == "" : {
              this.inputModels.splice(key, 2)
              this.setInputFocus(key)
              this.version++;
              break;
            }
          }
          this.tagsToString()
        }
        else if(evt.key == 'Click') {
          switch(true) {
            case this.inputModels[key+1].index == "" : {
              this.inputModels.splice(key, 2)
              this.setInputFocus(key-1)
              break;
            }
            case this.inputModels[key+1].index != "" : {
              this.inputModels[key-1].index += this.inputModels[key+1].index
              this.inputModels.splice(key, 2)
              this.setInputFocus(key-1)
              break;
            }
          }
          this.tagsToString()
        }
        else if(evt.key == 'ArrowLeft') {
          if (evt.ctrlKey || this.inputModels[key].index == '') {
            this.setInputFocus(key-2)
          }
        }
        else if(evt.key == 'ArrowRight') {
          if (evt.ctrlKey || this.inputModels[key].index == '') {
            this.setInputFocus(key+2)
          }
        }
        
        
      },
      setInputFocus(nextPos) {
        if(nextPos >= 0 && nextPos < this.inputModels.length) {
          this.$refs[this.getID(nextPos + this.version)][0].focus()
        }
      },
      setElements(key = null) {
        let text = ''
        //* Quando a função é chamada no evento de @blur do input é utilizada da "key" atual 
        if (key != null) {
          if (!this.inputModels[key]) return 
          text = this.inputModels[key].index
        }
        //* Quando prop value vem com String setada não há key no Array "inputModels"
        else {
          if (this.advanced) {
            text = this.register.value || ''
          } else {
            text = this.register || ''
          }
        }
        
        //* Regex que extrai todas string que contem "{{}}" ao redor e transforma em um Array
        let matches = [...text.matchAll(/{{(.{1,}?)}}/g)]; 
        
        //* Se houver variaveis na String de entrada do Regex
        if(matches.length) { 
          matches.forEach((el) => {
            let variable = null
            let string = null
            
            //*  Split na variável 'text' que contém a string de entrada, 
            //*  splitada no elemento de iteração do Array de resultado da Função de Regex.
            const val = text.split(el[0]);
            
            //* "out" recebe a primeira parte da string, podendo conter uma string inserida antes de um variável ou uma string vazia
            let out = val[0];

            //* Verifica se a variável detectada no Regex é um dos valores possíveis préviamente determinados
            if (this.possibleValues[el[0]]) {
              
              //* Caso exista é reconhecida e seu valor setado no input como badge
              variable = new Variable({ type: 'variable', value: this.possibleValues[el[0]], index: el[0], variant: 'light-info' })
            } else {
              
              //* Caso não exista é setada com "custom_variable" e seu index setado no input como badge
              variable = new Variable({ type: 'custom_variable', value: this.possibleValues[el[0]], index: el[0], variant: 'light-danger'})
            }

            //* Cria uma instancia de "Variable" para constantes, "out" sendo a String que existe antes da variável
            string = new Variable({ type: 'constant', value: '', index: out, variant: 'light-danger'})
            
            //? Funciona, mas acredito que o posicionamento de inserção possa ser generalizado
            if (key != null) {
              //* Se a 'key' atual da inserção de variáveis for a primeira posição do input 
              //* -> 1: recebe uma instancia de "Variable" com a string à direita da variável e coloca na primeira posição do array de elementos do input
              //* -> 2: insere a string e a variável no array de elementos do input, inserindo primeiro a variável, \n
              //*       depois inserindo "string" que era nossa primeira parte da string antes da variável detectada
              if (key == 0) {
                this.inputModels[key] = new Variable({ type: 'constant', value: '', index: val.at(-1), variant: 'light-danger'}) //* 1
                this.inputModels.unshift(string, variable)      
              } 
              //* Se a 'key' atual da inserção de variáveis for a última posição do input 
              //* -> 3: insere "string" na posição atual de "key" contendo a string antes da variável "out"
              //* -> 4: insere no final do input de elementos a variável 
              //*       e uma instancia de 'Variable' contendo o elemento á direita da variável (restante da informação) 
              else if (key == (this.inputModels.length - 1)) {
                this.inputModels[key] = string                                                                                             //* 3
                this.inputModels.push(variable, new Variable({ type: 'constant', value: '', index: val.at(-1), variant: 'light-danger'}) ) //* 4
              }
              //* Se a 'key' atual da inserção de variáveis for qualquer outra posição
              //* -> 5: "arrFragment" recebe uma parte do Array "inputModels" da posição de "key" até a posição final
              //* -> 6: a primeira posição de "arrFragment" recebe uma instância de "Variable" com o elemento a direita da variável ({{}}) que sofreu Split
              //* -> 7: insere a string e a variável no array de elementos do input, inserindo primeiro a variável, \n
              //*       depois inserindo "string" que era nossa primeira parte da string antes da variável detectada
              //* -> 8: o Array "inputModels" recebe a concatenação do que restou antes da posição 'key' que ainda está contido em "inputModels"
              //*       e o "arrFragment" que contem a as variáveis e strings inseridas no início, formando o array original atualizado.
              else {
                let arrFragment = this.inputModels.splice(key)                                                             //* 5
                arrFragment[0] = new Variable({ type: 'constant', value: '', index: val.at(-1), variant: 'light-danger'})  //* 6
                arrFragment.unshift(string, variable)                                                                      //* 7
                this.inputModels = this.inputModels.concat(arrFragment)                                                    //* 8
              }

              //* Caso mais de uma variável seja detectada numa mesma posição inserida por um input a "key" é incrementada em 2
              //* pois sempre que uma nova varíavel é inserida no Array de elementos, são criadas duas novas posições,
              //* sendo necessário um reajuste de posicionamento para a correta inserção das próximas variáveis e strings
              key += 2

            } else {
            
              //* Caso esteja processando uma String vindo diretamente da Prop, os elementos são somente inseridos na ordem que foram lidos
              this.inputModels.push(string, variable)
            }

            //* Efetua o replace na concatenção do val[0] (string anterior à variável) e o el[0] (a variável) e os substitue por uma String vazia
            //* Isso para que na próxima leitura dos elementos seja possível trabalhar somente com os dados que serão necessários
            text = text.replace(val[0] + el[0], '');
          })
        }
        //* Caso a leitura da string seja a partir dos dados da Prop insere uma posição no final da definição das variáveis com o restante da string original ou uma String vazia
        if (key == null) this.inputModels.push(new Variable({ type: 'constant', value: '', index: text, variant: 'light-danger'}));

        //* transforma o array de elementos na varíavel String final que será enviada para a API
        this.tagsToString()       
      },
      tagsToString() {
        let text = '';

        this.inputModels.forEach((item) => {
          text += item.index
        })
        
        if (this.advanced) {
          this.$set(this.register, 'value', text);
        } else {
          this.register = text
        }
        this.$emit('stringUpdated');
      },
      focusLast() {
        this.$refs[this.getID((this.inputModels.length - 1) + this.version)][0].focus()
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      getSource(id) {
        return new Source(id);
      },
      // Advanced Functions (Changer)
      getValue(type) {
        switch (type) {
          case 'source': {
            return this.getSource(this.register.source).label
          } 

        }
      },
      openChanger(type) {
        let selected = undefined
        switch (type) {
          case 'source': {
            selected = this.getSource(this.register.source)
            this.changerSelection = { ...selected, label: this.$t(selected.label) }
            this.selectChangerOptions = this.sourceOptions
          }
        }

        this.$bvModal.show(this.getID('custom-input-changer'))
      },
      submitChanger() {
        this.$set(this.register, 'source', this.changerSelection.id)
        this.$emit('value-changed', 'source')
      },
      copyCertificateToClipboard(){
        let certificate = this.register.value
        navigator.clipboard.writeText(certificate)
        makeToast({
          title: this.$t("common.toast.copy_to_clipboard.success.title"),
          text: this.$t("common.toast.copy_to_clipboard.success.message"),
          variant: "success",
          icon: "CheckIcon",
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
.variable-custom-input {
  font-family: monospace !important;
  width: auto !important;
  display: inline-block;
  border: none !important;
  padding: 0 !important;
  height: 23px  !important;
  box-shadow: none !important;
  min-width: 8.4px !important;

  &:focus-within {
    &::placeholder {
      color: transparent !important;
    }
  }
}

.custom-container {
  &:hover {
    .custom-input-horizontal {
      padding-bottom: 35px !important;
    }
  }
}

.custom-input {
  max-height: 100px !important;
}

.custom-input-disabled {
  pointer-events: none !important;
}

.custom-input-horizontal {
  overflow: hidden !important;
  width: max-content !important;
  min-width: 100%;
  max-height: 35px !important;
  transition: all 0.05s;
  background-color: #283046;
}

.pretend-form-input{
  resize: none !important;
}

.input-body{
  width: 92%;
}
.copy-btn{
  margin-top: 15px;
  padding-bottom: 10px !important;
  width: 5%;
}
.copy-btn-in{
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>